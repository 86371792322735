// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const purchaseInvoiceModalState = reactive({
	isModalVisible: false,
	ourCompany: '',
	customerId: '',
	stockId: '',
	lineInfo: '',
	keyModal: 1,
	isAddOrEdit: '',
})
export default function usePurchaseInvoiceModalState() {
	return {
		...toRefs(purchaseInvoiceModalState),
		purchaseInvoiceModalState,
	}
}
