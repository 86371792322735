/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'

Vue.use(VueCompositionApi)

const state = reactive({
	keyGetData: 1,
	purchaseInvoiceTotal: 0,
	purchaseInvoiceLinesArray: [],
	columnsList: [
		{
			label: 'Delete',
			field: 'delete',
			width: '35px',
			sortable: false,
		},
		{
			label: 'Line info',
			field: 'line_info',
			width: '300px',
		},
		{
			label: 'Line Amount',
			field: 'amount_debit',
			width: '60px',
		},
		{
			label: 'Supplier Price',
			field: 'supplierInvoicePriceExchangeFormatted',
			width: '70px',
		},
		{
			label: 'VAT',
			field: 'vat_percent',
			width: '70px',
		},
		{
			label: 'Line Total',
			field: 'lineTotal',
			width: '70px',
		},
		{
			label: 'Exc.',
			field: 'purchaseInvoiceExcUnit',
			width: '70px',
		},
	],
})

export default function useCurrentPurchaseInvoiceState() {
	return {
		...toRefs(state),
	}
}
