<template>
	<div>
		<div
			class="text-bold"
			v-if="showSubSummaryHeaders"
			:key="keySubSummaryHeaders"
		>
			Purchase Invoice Headers Summary
		</div>
		<div>{{ customer }}</div>
		<div>{{ purchaseInvoiceDate }}</div>
		<div>{{ purchaseInvoiceDueDate }}</div>
		<div>{{ purchaseInvoiceType }}</div>
		<div>{{ purchaseInvoiceExchangeUnit }}</div>
		<div>{{ purchaseInvoiceExchangeRate }}</div>
		<div>{{ purchaseInvoiceNumber }}</div>
		<div>{{ isLocked }}</div>
	</div>
</template>

<script>
import { computed, reactive } from '@vue/composition-api'
import usePurchaseInvoiceState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/usePurchaseInvoiceState.js'
import usePurchaseInvoiceHeaderState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState.js'
export default {
	name: 'SubSummaryPurchaseInvoiceHeaders',
	setup() {
		const { showSubSummaryHeaders, keySubSummaryHeaders } =
			usePurchaseInvoiceState()
		const {
			state,
			purchaseInvoiceType,
			purchaseInvoiceExchangeUnit,
			purchaseInvoiceExchangeRate,
			purchaseInvoiceSupplierNumber,
		} = usePurchaseInvoiceHeaderState()
		return reactive({
			customer: computed(
				() =>
					`Customer: ${state.customerIdPurchaseInvoice} - ${state.customerTitlePurchaseInvoice} - ${state.customerNicknamePurchaseInvoice}`,
			),
			purchaseInvoiceDate: computed(
				() => `Purchase Invoice Date: ${state.purchaseInvoiceIssueDate}`,
			),
			purchaseInvoiceDueDate: computed(
				() => `Purchase Invoice Due Date: ${state.purchaseInvoiceDueDate}`,
			),
			purchaseInvoiceType: computed(
				() => `Purchase Invoice Type: ${purchaseInvoiceType.value}`,
			),
			purchaseInvoiceNumber: computed(
				() => `Purchase Invoice Number: ${purchaseInvoiceSupplierNumber.value}`,
			),
			purchaseInvoiceExchangeUnit: computed(
				() => `Purchase Exchange Unit: ${purchaseInvoiceExchangeUnit.value}`,
			),
			purchaseInvoiceExchangeRate: computed(
				() => `Purchase Exchange Rate: ${purchaseInvoiceExchangeRate.value}`,
			),
			isLocked: computed(
				() => `Is Purchase Invoice Locked: ${state.invoiceLock}`,
			),
			showSubSummaryHeaders,
			keySubSummaryHeaders,
		})
	},
}
</script>

<style scoped></style>
