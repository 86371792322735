/** @format */

// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, {
	reactive,
	toRefs,
	// computed,
} from '@vue/composition-api'

Vue.use(VueCompositionApi)
import Store from '@/store'
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'

import useExchangeRate from '@/_srcv2/components/_shared/exchange-api/useExchangeRates.js'

const { getExchangeRates } = useExchangeRate()

const state = reactive({
	ourCompanyPurchaseInvoice: '',
	customerIdPurchaseInvoice: '',
	customerTitlePurchaseInvoice: '',
	customerNicknamePurchaseInvoice: '',
	keyPurchaseInvoiceHeaders: 1,
	isDatePickerDisabled: false,
	purchaseInvoiceIssueDate: '',
	purchaseInvoiceDueDate: '',
	supplierAddressArray: [],
	supplierCountryArray: [],
	invoiceLock: '',
})

const modelPurchaseInvoice = reactive({
	showExchangeFieldsOnForm: false,
	purchaseInvoiceType: 'Inland',
	purchaseInvoiceExchangeUnit: 'kr.',
	purchaseInvoiceExchangeRate: 1.0,
	purchaseInvoiceSupplierNumber: '',
	// ? -------------------------------------------
	supplierAddressId: '',
})
const schemaPurchaseInvoice = reactive({
	groups: [
		{
			fields: [
				{
					type: 'select',
					label: 'Supplier Address',
					model: 'supplierAddressId',
					placeholder: "Please select Purchase Invoice Company's address",
					required: true,
					featured: true,
					readonly: false,
					disabled: false,
					values: [],
				},
				{
					type: 'select',
					label: 'Purchase Invoice Type',
					model: 'purchaseInvoiceType',
					id: 'purchase-invoice-type',
					hint: 'Please select purchase sale-invoice type',
					values: ['Inland', 'Inside EU', 'Outside EU'],
					featured: true,
					required: true,
					readonly: false,
					disabled: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							console.log(
								'Validation error in Purchase Invoice type field! Errors:',
								errors,
							)
						} else {
							if (
								model.purchaseInvoiceType === 'Inside EU' ||
								model.purchaseInvoiceType === 'Outside EU'
							) {
								model.showExchangeFieldsOnForm = true
							} else {
								model.showExchangeFieldsOnForm = false
								model.purchaseInvoiceExchangeUnit = 'kr.'
								model.purchaseInvoiceExchangeRate = 1.0
							}
						}
					},
				},
				{
					// Only appear if packet value is true
					// visible(model) {
					// 	return model && model.showExchangeFieldsOnForm === true
					// },
					type: 'select',
					label: 'PurchaseInvoice Exchange Unit',
					model: 'purchaseInvoiceExchangeUnit',
					hint: 'Please select Purchase Invoice exchange unit',
					values: ['kr.', '€', '$', 'NOK', 'DKK', 'TRY', 'PLN'],
					featured: true,
					required: true,
					readonly: false,
					disabled: false,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							Store.dispatch('setDisableSavePurchaseInvoiceHeaders', true)
							console.log(
								'Validation error in PurchaseInvoice Exchange Unit field! Errors:',
								errors,
							)
						} else if (model.purchaseInvoiceExchangeUnit === 'kr.') {
							console.log('kr. is selected')
							model.purchaseInvoiceExchangeRate = 1.0
						} else {
							console.log(`${model.purchaseInvoiceExchangeUnit} is selected`)
							const getExcUnit = (unit) => {
								switch (unit) {
									case '$':
										return 'USD'
									case '€':
										return 'EUR'
									case 'TRY':
										return 'TRY'
									case 'NOK':
										return 'NOK'
									case 'DKK':
										return 'DKK'
									case 'PLN':
										return 'PLN'
									default:
										return 'kr.'
								}
							}
							getExchangeRates(
								getExcUnit(model.purchaseInvoiceExchangeUnit),
							).then((excRate) => {
								console.log('From schema excRate', excRate)
								model.purchaseInvoiceExchangeRate = excRate
								model.isDatePickerDisabled = true
							})
						}
					},
				},
				{
					type: 'cleave',
					label: 'Purchase Invoice Exchange Rate',
					model: 'purchaseInvoiceExchangeRate',
					placeholder: 'Please enter Purchase Invoice exchange rate',
					featured: true,
					required: true,
					readonly: false,
					disabled: false,
					validator: ['positivenumber', 'required'],
					cleaveOptions: {
						numeral: true,
						numeralThousandsGroupStyle: 'thousand',
						numeralDecimalScale: 9,
						numeralDecimalMark: '.',
					},
					onValidated: function (model, errors) {
						if (errors.length > 0) {
							console.log('Error!!!')
							console.log(
								'Validation error in Purchase Invoice Exchange Rate field! Errors:',
								errors,
							)
							// console.log('*******', Store.getters.getDisableSavePurchaseInvoiceHeaders)
						} else {
							console.log('NO ERROR!!!')
						}
					},
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Purchase Invoice Supplier Number',
					model: 'purchaseInvoiceSupplierNumber',
					id: 'purchase-invoice-supplier-number',
					readonly: false,
					featured: true,
					disabled: false,
					required: true,
					validator: ['string'],
					onValidated: function (model, errors) {
						if (errors.length > 0)
							console.log(
								'Validation error in Purchase Invoice Supplier Number field! Errors:',
								errors,
							)
						else {
							// Store.dispatch(
							//   'setCurrentPurchaseInvoiceType',
							//   model.purchaseInvoiceType
							// )
						}
					},
				},
			],
		},
	],
})
const formOptionsPurchaseInvoice = reactive({
	validateAfterLoad: true,
	validateAfterChanged: true,
	validateAsync: true,
	fieldIdPrefix: 'purchaseInvoiceForm',
})

export default function usePurchaseInvoiceHeaderState() {
	return {
		state,
		...toRefs(state),
		modelPurchaseInvoice,
		schemaPurchaseInvoice,
		formOptionsPurchaseInvoice,
		...toRefs(modelPurchaseInvoice),
	}
}
