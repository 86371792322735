/** @format */

// ? To share the state between components write thw following three lines
// ? Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	purchaseInvoiceType: '',
	showSearchBox: true,
	showGetPurchaseInvoiceHeaders: false,
	showSelectPurchaseInvoiceHeadersDetails: false,
	showPreview: false,
	showSubSummaryHeaders: false,
	keySubSummaryHeaders: 1,
	showCreatePurchaseInvoice: false,
	columnsList: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Supplier ID',
			field: 'customer_id',
			width: '135px',
			thClass: 'center-it',
		},
		{
			label: 'Supplier Title',
			field: 'customer_title',
			thClass: 'center-it',
		},
		{
			label: 'Supplier Nickname',
			field: 'customer_nickname',
			thClass: 'center-it',
		},
		{
			label: 'Org.Number',
			field: 'customer_org_num',
			width: '135px',
			thClass: 'center-it',
		},
	],
})

export default function usePurchaseInvoiceState() {
	return {
		...toRefs(state),
	}
}
