<!-- @format -->

<!-- eslint-disable no-useless-escape -->
<template>
	<div>
		<form id="form" class="margin-top-15">
			<span class="exchange-title">All the prices are in</span>&nbsp;
			<span class="exchange-title">{{ excUnit }}</span>
			<div class="exchange-unit">
				1 sek =
				{{ modelPurchaseInvoice.purchaseInvoiceExchangeRate }}&nbsp;{{
					excUnit
				}}
			</div>
			<vue-form-generator
				@validated="onFormValidated"
				:schema="schemaPurchaseInvoiceLines"
				:model="modelPurchaseInvoiceLines"
				:options="formOptionsPurchaseInvoiceLines"
			>
			</vue-form-generator>
		</form>

		<div v-if="purchaseInvoiceModalState.isAddOrEdit === 'add'">
			<b-button
				:disabled="!fieldsAreFull"
				class="is-info margin-top-25"
				type="submit"
				@click="onSubmit"
				v-if="true"
				>Add Purchase Invoice Item
			</b-button>
		</div>
		<div v-if="purchaseInvoiceModalState.isAddOrEdit === 'edit'">
			<b-button
				style="width: 350px"
				:disabled="!fieldsAreFull"
				class="is-info margin-top-25"
				type="submit"
				@click="onUpdate"
				v-if="true"
				>Add Purchase Invoice Item & Update
			</b-button>
		</div>
	</div>
</template>

<script>
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import { useMutation } from '@vue/apollo-composable'
import InsertPurchaseInvoiceLineMutation from '@/_srcv2/pages/purchase-invoice/edit-purchase-invoice/graphql/InsertPurchaseInvoiceLineMutation.graphql'
import usePurchaseInvoiceModalState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/add-purchase-invoice-lines/usePurchaseInvoiceModalState.js'
import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid'
import usePurchaseInvoiceHeaderState from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/get-purchase-invoice-headers/usePurchaseInvoiceHeaderState.js'
import storeCurrentPurchaseInvoice from '@/_srcv2/pages/purchase-invoice/add-purchase-invoice/subcomponents/current-purchase-invoice/useCurrentPurchaseInvoiceState.js'
import numeral from 'numeral'

export default {
	name: 'AddPurchaseInvoiceLinesModal',
	setup() {
		const { purchaseInvoiceModalState } = usePurchaseInvoiceModalState()
		// todo ---------------------------------------------------------------------------
		const { modelPurchaseInvoice, state } = usePurchaseInvoiceHeaderState()
		const excUnit = computed(
			() => modelPurchaseInvoice.purchaseInvoiceExchangeUnit,
		)
		// todo --------------------------------------------------------------------------------
		// ? regex statements
		// ? Use a regular expression to match spaces and commas globally
		const regex = /[\s]/g
		// eslint-disable-next-line no-useless-escape
		const regexComa = /[\,]/g
		// eslint-disable-next-line no-useless-escape
		const regexDot = /[\.]/g
		// todo ---------------------------------------------------------------------------
		const getPriceInSek = () => {
			// ? Replace all matches with an empty string
			const newString = modelPurchaseInvoiceLines.supplierInvoicePriceExchange
				.replace(regex, '')
				.replace(regexComa, '.')
			const excAsInt = parseInt(
				modelPurchaseInvoice.purchaseInvoiceExchangeRate * 10000000000,
			)
			return numeral((parseFloat(newString) / excAsInt) * 10000000000)
				.format('0,0.00')
				.replace(regexComa, ' ')
				.replace(regexDot, ',')
		}
		// todo -----------------------------------------------------------------
		const getPriceInExchangeFormatted = (payload) => {
			// ? Replace all matches with an empty string
			const newString = payload.replace(regex, '').replace(regexComa, '.')
			console.log('newString', newString)
			console.log('typeof newString', typeof newString)
			return numeral(parseFloat(newString))
				.format('0,0.00')
				.replace(regexComa, ' ')
				.replace(regexDot, ',')
		}

		// todo -----------------------------------------------------------------
		const getLineTotal = (payload) => {
			console.log('payload', payload)
			console.log('typeof payload', typeof payload)
			return numeral(payload / 100)
				.format('0,0.00')
				.replace(regexComa, ' ')
				.replace(regexDot, ',')
		}
		// todo -----------------------------------------------------------------
		const getPricesForDB = () => {
			// ? Use a regular expression to match spaces and commas globally
			const newString = modelPurchaseInvoiceLines.supplierInvoicePriceExchange
				.replaceAll(' ', '')
				.replace(',', '.')
			modelPurchaseInvoiceLines.invoice_price_exchange =
				parseFloat(newString).toFixed(2) * 100
			// ? -----------------------------------------------------------------------
			const newString2 = modelPurchaseInvoiceLines.supplierInvoicePrice
				.replaceAll(' ', '')
				.replace(',', '.')
			modelPurchaseInvoiceLines.invoice_price = Math.round(
				parseFloat(newString2).toFixed(2) * 100,
			)
			console.log(
				'modelPurchaseInvoiceLines.invoice_price',
				modelPurchaseInvoiceLines.invoice_price,
			)
			console.log(
				'typeof modelPurchaseInvoiceLines.invoice_price',
				typeof modelPurchaseInvoiceLines.invoice_price,
			)
			modelPurchaseInvoiceLines.unit_price =
				modelPurchaseInvoiceLines.invoice_price_exchange
			// ? -----------------------------------------------------------------------
			modelPurchaseInvoiceLines.line_price_total_debit =
				modelPurchaseInvoiceLines.invoice_price *
				modelPurchaseInvoiceLines.amount_debit
			modelPurchaseInvoiceLines.line_price_total_debit_exchange =
				modelPurchaseInvoiceLines.invoice_price_exchange *
				modelPurchaseInvoiceLines.amount_debit
			modelPurchaseInvoiceLines.lineTotal = getLineTotal(
				modelPurchaseInvoiceLines.line_price_total_debit_exchange,
			)
			// ? -----------------------------------------------------------------------
			modelPurchaseInvoiceLines.vat_debit = parseInt(
				(modelPurchaseInvoiceLines.line_price_total_debit *
					modelPurchaseInvoiceLines.vat_percent) /
					100,
			)
			modelPurchaseInvoiceLines.vat_debit_exchange = parseInt(
				(modelPurchaseInvoiceLines.line_price_total_debit_exchange *
					modelPurchaseInvoiceLines.vat_percent) /
					100,
			)
			// todo ---------------------------------------------------------------------
			console.log(
				'getPricesForDB => modelPurchaseInvoiceLines',
				modelPurchaseInvoiceLines,
			)
		}
		// todo -----------------------------------------------------------------------------
		const fieldsAreFull = computed(() => {
			const fields = [
				modelPurchaseInvoiceLines.vat_percent,
				modelPurchaseInvoiceLines.supplierInvoicePriceExchange,
				modelPurchaseInvoiceLines.amount_debit,
			]
			return fields.every(
				(field) => field !== '' && field !== null && field !== undefined,
			)
		})
		// ? -------------------------------------------------------------------------------------
		const setModelValues = () => {
			console.log('fieldsAreFull.value', fieldsAreFull.value)
			if (!fieldsAreFull.value) {
				return
			} else if (purchaseInvoiceModalState.isAddOrEdit === 'add') {
				// ? ------------------------------------------------------------
				modelPurchaseInvoiceLines.supplierInvoicePrice = getPriceInSek()
				;(modelPurchaseInvoiceLines.supplierInvoicePriceExchangeFormatted =
					getPriceInExchangeFormatted(
						modelPurchaseInvoiceLines.supplierInvoicePriceExchange,
					)),
					getPricesForDB()
			} else if (purchaseInvoiceModalState.isAddOrEdit === 'edit') {
				modelPurchaseInvoiceLines.supplierInvoicePrice = getPriceInSek()
				;(modelPurchaseInvoiceLines.supplierInvoicePriceExchangeFormatted =
					getPriceInExchangeFormatted(
						modelPurchaseInvoiceLines.supplierInvoicePriceExchange,
					)),
					console.log('edit')
				getLineForDB()
			}
		}
		// todo --------------------------------------------------------------------------------
		const { getUUID } = useGetUuid()
		onMounted(() => {
			modelPurchaseInvoiceLines.goods_transaction_id = getUUID()
			modelPurchaseInvoiceLines.invoice_number = `${state.customerIdPurchaseInvoice}-${modelPurchaseInvoice.purchaseInvoiceSupplierNumber}`
			modelPurchaseInvoiceLines.transaction_type = 'purchase invoice'
			modelPurchaseInvoiceLines.stock_id = purchaseInvoiceModalState.stockId
			modelPurchaseInvoiceLines.line_info = purchaseInvoiceModalState.lineInfo
			modelPurchaseInvoiceLines.amount_debit = null
			modelPurchaseInvoiceLines.unit_price = null
			if (modelPurchaseInvoice.purchaseInvoiceType !== 'Inland') {
				modelPurchaseInvoiceLines.vat_percent = 0
				schemaPurchaseInvoiceLines.groups[0].fields[1].disabled = true
			} else {
				modelPurchaseInvoiceLines.vat_percent = null
				schemaPurchaseInvoiceLines.groups[0].fields[1].disabled = false
			}
			modelPurchaseInvoiceLines.vat_debit = null
			modelPurchaseInvoiceLines.invoice_price = null
			modelPurchaseInvoiceLines.line_price_total_debit = null
			modelPurchaseInvoiceLines.vat_debit_exchange = null
			modelPurchaseInvoiceLines.invoice_price_exchange = null
			modelPurchaseInvoiceLines.line_price_total_debit_exchange = null
			// ? for the table ------------------------------------------------
			modelPurchaseInvoiceLines.supplierInvoicePrice = null
			modelPurchaseInvoiceLines.supplierInvoicePriceExchange = null
			modelPurchaseInvoiceLines.supplierInvoicePriceExchangeFormatted = null
			modelPurchaseInvoiceLines.lineTotal = null
			modelPurchaseInvoiceLines.purchaseInvoiceExcUnit = excUnit
			console.log(
				'onMounted modelPurchaseInvoiceLines',
				modelPurchaseInvoiceLines,
			)
		})
		// --------------------------------------------------------------------------------
		console.log(
			' getPricesForDB => AddPurchase InvoiceLinesModal onCreated is fired',
		)
		const modelPurchaseInvoiceLines = reactive({
			line_info: '',
			amount_debit: '',
			unit_price: '',
			vat_percent: '',
			lineTotal: '',
			purchaseInvoiceExcUnit: '',
			// *****************************************
			goods_transaction_id: '',
			invoice_number: '',
			transaction_type: 'purchase invoice',
			stock_id: '',
			invoice_price: '',
			invoice_price_exchange: '',
			vat_debit: '',
			line_price_total_debit: '',
			vat_debit_exchange: '',
			line_price_total_debit_exchange: '',
			supplierInvoicePrice: '',
			supplierInvoicePriceExchange: '',
			supplierInvoicePriceExchangeFormatted: '',
		})
		const schemaPurchaseInvoiceLines = reactive({
			groups: [
				{
					legend: 'Stock Item to add to Purchase Invoice',
					id: 'purchaseInvoiceLines',
					featured: true,
					fields: [
						{
							type: 'input',
							inputType: 'text',
							label:
								'Purchase Invoice info (stock id - item name - unit) You can edit if you need',
							model: 'line_info',
							placeholder: 'Please enter Information',
							readonly: true,
							featured: true,
							disabled: false,
							required: true,
							validator: ['string'],
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Information field! Errors:',
										errors,
									)
							},
						},
						{
							type: 'input',
							inputType: 'number',
							label: 'VAT %',
							model: 'vat_percent',
							placeholder: 'Please enter VAT percent of the Item',
							readonly: false,
							featured: true,
							disabled: false,
							required: true,
							validator: ['integer'],
							onValidated: function (model, errors) {
								if (errors.length > 0) {
									console.log(
										'Validation error in Purchase Invoice Amount field! Errors:',
										errors,
									)
								} else {
									setModelValues()
								}
							},
						},
						// -----------------------------------------------------------------------------
						{
							type: 'cleave',
							label: `Item Invoice Price in kr.`,
							model: 'supplierInvoicePrice',
							placeholder: 'Please get stock item sale-invoice price',
							featured: true,
							disabled: true,
							readonly: true,
							required: true,
							validator: [],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Price field! Errors:',
										errors,
									)
							},
						},
						{
							type: 'cleave',
							label: `Item Invoice Price Exchange in ${excUnit.value}`,
							model: 'supplierInvoicePriceExchange',
							placeholder: 'Please get supplier sale-invoice exchange price',
							featured: true,
							disabled: false,
							readonly: false,
							required: true,
							validator: [],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Price field! Errors:',
										errors,
									)
								else {
									setModelValues()
								}
							},
						},
						//------------------------------------------------------------------------------
						{
							type: 'input',
							inputType: 'number',
							label: 'Purchase Invoice Amount',
							model: 'amount_debit',
							placeholder: 'Please enter Purchase Invoice Amount',
							readonly: false,
							featured: true,
							disabled: false,
							required: true,
							validator: ['integer'],
							onValidated: function (model, errors) {
								if (errors.length > 0) {
									console.log(
										'Validation error in Purchase Invoice Amount field! Errors:',
										errors,
									)
								} else {
									setModelValues()
								}
							},
						},
					],
				},
			],
		})
		const formOptionsPurchaseInvoiceLines = reactive({
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateAsync: true,
			fieldIdPrefix: 'purchaseInvoiceLines',
		})
		// todo ---------------------------------------------------------------
		const onFormValidated = () => {
			console.log('Form is validated')
		}
		// todo ----------------------------------------------------------------------
		// ? Add the new purchase invoice line to array
		const getRecord = () => {
			console.log(
				'*-*-*-  getRecord beginning modelPurchaseInvoiceLines',
				modelPurchaseInvoiceLines,
			)
			return new Promise((resolve) => {
				const record = {
					goods_transaction_id: modelPurchaseInvoiceLines.goods_transaction_id,
					transaction_type: modelPurchaseInvoiceLines.transaction_type,
					invoice_number: modelPurchaseInvoiceLines.invoice_number,
					stock_id: modelPurchaseInvoiceLines.stock_id,
					line_info: modelPurchaseInvoiceLines.line_info,
					amount_debit: modelPurchaseInvoiceLines.amount_debit,
					unit_price: modelPurchaseInvoiceLines.unit_price,
					vat_percent: modelPurchaseInvoiceLines.vat_percent,
					vat_debit: modelPurchaseInvoiceLines.vat_debit,
					invoice_price: modelPurchaseInvoiceLines.invoice_price,
					line_price_total_debit:
						modelPurchaseInvoiceLines.line_price_total_debit,
					vat_debit_exchange: modelPurchaseInvoiceLines.vat_debit_exchange,
					invoice_price_exchange:
						modelPurchaseInvoiceLines.invoice_price_exchange,
					line_price_total_debit_exchange:
						modelPurchaseInvoiceLines.line_price_total_debit_exchange,
					// ? -------------------------------------------------------------
					purchaseInvoiceExcUnit: excUnit.value,
					supplierInvoicePriceExchange:
						modelPurchaseInvoiceLines.supplierInvoicePriceExchange,
					lineTotal: modelPurchaseInvoiceLines.lineTotal,
					supplierInvoicePriceExchangeFormatted:
						modelPurchaseInvoiceLines.supplierInvoicePriceExchangeFormatted,
				}
				console.log('record', record)
				resolve(record)
			})
		}
		// ? Push new purchase invoice line
		const { purchaseInvoiceLinesArray } = storeCurrentPurchaseInvoice()
		const onSubmit = () => {
			// push to array
			getRecord()
				.then((record) => {
					purchaseInvoiceLinesArray.value.push(record)
				})
				.then(() => {
					purchaseInvoiceModalState.isModalVisible = false
				})
		}
		// todo -------------------------------------------------------------
		const mutationVariable = ref([])
		const { mutate: insertOrderLine } = useMutation(
			InsertPurchaseInvoiceLineMutation,
			() => ({
				variables: {
					input: mutationVariable.value,
				},
			}),
		)
		// todo ----------------------------------------------------------------------
		const modelLine = {
			line_info: '',
			amount_debit: '',
			unit_price: '',
			vat_percent: '',
			// *****************************************
			goods_transaction_id: '',
			invoice_number: '',
			transaction_type: 'purchase invoice',
			stock_id: '',
			invoice_price: '',
			invoice_price_exchange: '',
			vat_debit: '',
			line_price_total_debit: '',
			vat_debit_exchange: '',
			line_price_total_debit_exchange: '',
		}
		// todo -----------------------------------------------------------------
		const getLineForDB = () => {
			modelLine.goods_transaction_id = getUUID()
			modelLine.line_info = modelPurchaseInvoiceLines.line_info
			modelLine.amount_debit = modelPurchaseInvoiceLines.amount_debit

			modelLine.vat_percent = modelPurchaseInvoiceLines.vat_percent
			modelLine.stock_id = modelPurchaseInvoiceLines.stock_id
			modelLine.invoice_number =
				modelPurchaseInvoice.purchaseInvoiceSupplierNumber
			modelLine.line_info = modelPurchaseInvoiceLines.line_info
			// ? Use a regular expression to match spaces and commas globally
			const newString = modelPurchaseInvoiceLines.supplierInvoicePriceExchange
				.replaceAll(' ', '')
				.replace(',', '.')
			modelLine.invoice_price_exchange = parseFloat(newString).toFixed(2) * 100
			modelLine.unit_price = parseFloat(newString).toFixed(2) * 100
			// ? -----------------------------------------------------------------------
			const newString2 = modelPurchaseInvoiceLines.supplierInvoicePrice
				.replaceAll(' ', '')
				.replace(',', '.')
			modelLine.invoice_price = Math.round(
				parseFloat(newString2).toFixed(2) * 100,
			)
			// ? -----------------------------------------------------------------------

			modelLine.line_price_total_debit =
				modelLine.invoice_price * modelLine.amount_debit
			modelLine.line_price_total_debit_exchange =
				modelLine.invoice_price_exchange * modelLine.amount_debit
			console.log('modelLine.invoice_price', modelLine.invoice_price)
			console.log('modelLine.amount_debit', modelLine.amount_debit)
			console.log(
				'modelLine.line_price_total_debit',
				modelLine.line_price_total_debit,
			)
			console.log(
				'modelLine.invoice_price_exchange',
				modelLine.invoice_price_exchange,
			)
			console.log('modelLine.amount_debit', modelLine.amount_debit)
			console.log(
				'modelLine.line_price_total_debit_exchange',
				modelLine.line_price_total_debit_exchange,
			)
			// ? -----------------------------------------------------------------------
			modelLine.vat_debit = parseInt(
				(modelLine.line_price_total_debit * modelLine.vat_percent) / 100,
			)
			modelLine.vat_debit_exchange = parseInt(
				(modelLine.line_price_total_debit_exchange * modelLine.vat_percent) /
					100,
			)
			// todo ---------------------------------------------------------------------
			console.log('getLineForDB => modelLine', modelLine)
		}
		const condition = true
		const pushLine = () => {
			return new Promise((resolve, reject) => {
				if (condition) {
					mutationVariable.value.push(modelLine)
					resolve(mutationVariable.value)
				} else {
					const reason = new Error('Data could not be pushed to database')
					reject(reason)
				}
			})
		}
		// todo --------------------------------------------------------------------
		const onUpdate = () => {
			console.log('=====>>>>>> modelLine', modelLine)
			pushLine().then((result) => {
				console.log('result', result)
				console.log('mutationVariable', mutationVariable.value)
				insertOrderLine()
				purchaseInvoiceModalState.isModalVisible = false
			})
		}
		// todo ----------------------------------------------------------------------
		return {
			modelPurchaseInvoice,
			schemaPurchaseInvoiceLines,
			modelPurchaseInvoiceLines,
			formOptionsPurchaseInvoiceLines,
			onSubmit,
			onFormValidated,
			fieldsAreFull,
			purchaseInvoiceModalState,
			onUpdate,
			excUnit,
		}
	},
}
</script>
<style scoped>
.margin-top-25 {
	margin-top: 25px;
}
.margin-top-15 {
	margin-top: 15px;
}
.exchange-title {
	font-weight: bold;
	font-size: 1.5em;
	color: red;
}
.exchange-unit {
	font-weight: bold;
	font-size: 1.5em;
	color: green;
}
</style>
